<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron órdenes de trabajo"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :load="loadItems"
      :showing-sorter.sync="showingDialogs.sorter"
      :reload-dirty-items="reloadDirtyItems"
      :toolbar-options="toolbarOptions"
      :page-loaded="pageLoaded"
      pk-name="idorden_trabajo"
      @click-toolbar-option="clickToolbarOption"
      @click-item="clickListItem"
      @open-filter="openFilter"
    >
      <template
        #listItem="slotProps"
      >
        <orden-trabajo-list-item
          :item="slotProps.item"
        />
      </template>
    </b10-list>
    <orden-trabajo-list-legend
      :showing.sync="showingDialogs.legends"
    />
    <b10-map-markers
      :markers="mapMarkers"
      title="Mapa órdenes"
      :showing.sync="showingDialogs.map"
      icon-fieldname="icon"
      @bounds-changed="mapBoundsChanged"
      @click-marker="clickMapMarker"
    />
    <b10-map-marker-info
      :title="mapMarkerInfo.title"
      :subtitle="mapMarkerInfo.subtitle"
      :lat="mapMarkerInfo.lat"
      :lng="mapMarkerInfo.lng"
      :showing.sync="showingDialogs.mapMarkerInfo"
    >
      <template slot="cardExtraButtons">
        <v-btn
          color="info"
          dark
          @click="clickVerOT(mapMarkerInfo.idordenTrabajo)"
        >
          <v-icon left>
            {{ $vuetify.icons.values.orden }}
          </v-icon> Ver orden
        </v-btn>
      </template>
    </b10-map-marker-info>
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './OrdenTrabajoListData'
import OrdenTrabajoListItem from './components/OrdenTrabajoListItem'
import OrdenTrabajoListLegend from './components/OrdenTrabajoListLegend'
import { get } from 'vuex-pathify'
import _ from '@/utils/lodash'
import { markerIcons } from '@/utils/maps'
import { CLASIFICACION_ESTADO_ORDEN_TRABAJO } from '@/utils/consts'

const pageStoreName = 'pagesOrdenTrabajoList'

export default {
  components: {
    OrdenTrabajoListItem, OrdenTrabajoListLegend,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  props: {
    clasificacionEstado: {
      type: Array,
      default: () => [],
    },
    soloMisOT: {
      type: Boolean,
    },
  },
  data () {
    return {
      pageStoreName,
      toolbarOptions: {
        map: {
          title: 'Ver en el mapa',
          visible: true,
          icon: 'map',
        },
        legend: {
          title: 'Colores y leyendas',
          visible: true,
          icon: 'info',
        },
      },
      showingDialogs: {
        legends: false,
        mapMarkerInfo: false,
        map: false,
      },
      mapMarkerInfo: {
        title: '',
        subtitle: '',
        lat: null,
        lng: null,
      },
      mapMarkers: [],
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    usuarioIdtecnico: get('usuario/idtecnico'),
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      if (this.clasificacionEstado.length === 1) {
        if (this.clasificacionEstado[0] === CLASIFICACION_ESTADO_ORDEN_TRABAJO.clasificacion.pendiente) {
          if (this.filter.soyResponsable.value) {
            this.title = 'Órdenes de trabajo pendientes (soy responsable)'
          } else {
            this.title = 'Órdenes de trabajo pendientes (sin asignar)'
          }
        } else {
          this.title = 'Órdenes de trabajo finalizadas'
        }
      } else {
        this.title = 'Órdenes de trabajo'
      }
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectOrdenTrabajo(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.clasificacionEstado,
          this.soloMisOT,
          this.usuarioIdtecnico,
          this.routeParams?.idsistema,
          null,
          this.$api.ITEMS_PER_PAGE,
          this.routeParams?.idcliente
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        const [dataset] = await Data.selectOrdenTrabajoRows(
          this,
          dirty.modified,
          this.clasificacionEstado,
          this.soloMisOT,
          this.usuarioIdtecnico
        )
        await this.reloadItemsBase(dataset, dirty, 'idorden_trabajo')
      } finally {
        this.loadingData = false
      }
    },
    async openFilter () {
      // en evento openFilter y no en propiedad initFilter para no cargar
      // datos de los select hasta que no se abre el dialog del filtro
      if (
        this.filter.zona.items.length === 0 &&
        this.filter.tactuacion.items.length === 0 &&
        this.filter.tsistema.items.length === 0 &&
        this.filter.grupoCliente.items.length === 0 &&
        this.filter.tmotivoBloqueoOt.items.length === 0 &&
        this.filter.comercial.items.length === 0 &&
        this.filter.tecnico.items.length === 0
      ) {
        const resp = await Data.selectFilterLookups(this)
        await this.setStoreProperty('filter@zona.items', resp.data.selectZona.result.dataset)
        await this.setStoreProperty('filter@tactuacion.items', resp.data.selectTactuacion.result.dataset)
        await this.setStoreProperty('filter@tsistema.items', resp.data.selectTsistema.result.dataset)
        await this.setStoreProperty('filter@grupoCliente.items', resp.data.selectGrupoCliente.result.dataset)
        await this.setStoreProperty('filter@tmotivoBloqueoOt.items', resp.data.selectTmotivoBloqueoOt.result.dataset)
        await this.setStoreProperty('filter@comercial.items', resp.data.selectComercial.result.dataset)
        await this.setStoreProperty('filter@tecnico.items', resp.data.selectTecnico.result.dataset)
      }
    },
    clickToolbarOption (option) {
      if (option === this.toolbarOptions.legend) {
        this.showingDialogs.legends = true
      } else if (option === this.toolbarOptions.map) {
        this.showingDialogs.map = true
      }
    },
    clickMapMarker (markerData) {
      this.mapMarkerInfo.title = this.$online.ordenTrabajo.title(markerData)
      this.mapMarkerInfo.subtitle = this.$online.ordenTrabajo.subtitle(markerData)
      this.mapMarkerInfo.lat = markerData.latitud
      this.mapMarkerInfo.lng = markerData.longitud
      this.mapMarkerInfo.idordenTrabajo = markerData.idorden_trabajo
      this.showingDialogs.mapMarkerInfo = !this.showingDialogs.mapMarkerInfo
    },
    async mapBoundsChanged (bounds) {
      let [markers] = await Data.selectOrdenTrabajo(
        this,
        this.filter,
        this.search,
        this.sorter,
        0,
        this.clasificacionEstado,
        this.soloMisOT,
        this.usuarioIdtecnico,
        this.routeParams?.idsistema,
        bounds,
        this.$api.ITEMS_PER_PAGE,
        this.routeParams?.idcliente,
      )
      markers = _.map(markers, (marker) => {
        marker.icon = markerIcons.ordenTrabajo
        return marker
      })
      this.mapMarkers = markers
    },
    goToView (idordenTrabajo) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'ordenes__orden-trabajo-view',
        params: {
          idorden_trabajo: idordenTrabajo
        }
      })
    },
    clickListItem (item) {
      this.goToView(item.data.idorden_trabajo)
    },
    clickVerOT(idordenTrabajo) {
      this.showingDialogs.map = !this.showingDialogs.map
      this.showingDialogs.mapMarkerInfo = !this.showingDialogs.mapMarkerInfo
      this.goToView(idordenTrabajo)
    },
  },
}
</script>
